import {
    Breakpoints,
    Container,
    ContainerPadding,
    styled,
    Text,
    TextAlignment,
    TextTag,
    TokenTextAppearance,
} from '@volkswagen-onehub/components-core'
import React, {useEffect, useState} from 'react'
import {Step} from '../../types/step'
import {StepContainerBorder} from '../../shared/classes'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../rootReducer'
import {AppDispatch, fetchSteps} from '../thunk'
import {Loading} from './Loading'
import isEmpty from 'lodash.isempty'
import {EditComponent} from './EditComponent'
import {nextStep} from '../../shared/utilities'
import {
    eventEnvironmentCommon,
    eventInfoCommon,
    TrackingAction,
    trackingData,
    trackingEvent,
} from '../../shared/trackingServiceHelper'
import store from '../store'
import {Global, URL_PARAMS} from '../../shared/variables'

const CardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${Breakpoints.b560}px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
`

const BorderCard = styled.div`
    border: 2px solid ${({theme}) => theme.interaction.recipe300.default};
    padding: 1rem;
    display: flex;
    cursor: pointer;
    text-align: center;
    flex-direction: column;
    margin-bottom: ${(props) => props.theme.size.static200};
    color: ${({theme}) => theme.interaction.recipe300.default};

    @media (min-width: ${Breakpoints.b560}px) {
        margin-right: ${(props) => props.theme.size.static200};
        margin-left: ${(props) => props.theme.size.static200};
        margin-bottom: ${(props) => props.theme.size.static400};
        width: 180px;
        min-height: 180px;
        justify-content: center;
    }

    @media (min-width: ${Breakpoints.b1600}px) {
        width: 250px;
        min-height: 250px;
    }
`

const KmContainer = styled.div`
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;

    span {
        margin-bottom: 0;
        margin-right: 6px;
        @media (min-width: ${Breakpoints.b560}px) {
            margin: ${(props) => props.theme.size.static200} 0;
        }
    }

    p {
        @media (min-width: ${Breakpoints.b560}px) {
            margin-top: ${(props) => props.theme.size.static100};
        }
    }

    @media (min-width: ${Breakpoints.b560}px) {
        flex-direction: column;
        align-items: center;
    }
`

interface CardComponentProps {
    data: Step
    step_order: number
    currentStep: number
}

export function CardsComponent(props: CardComponentProps): React.JSX.Element {
    const dispatch = useDispatch() as AppDispatch

    const [loadingData, setLoadingData] = useState(false)
    const [editableData, setEditableData] = useState(true)
    const [selectedCard, setSelectedCard] = useState('')

    const cardsValues = useSelector(
        (state: RootState) => state.steps.steps[props.step_order - 1]
    )
    const editingMode = useSelector(
        (state: RootState) => state.steps.editingMode
    )
    const filterName = useSelector(
        (state: RootState) =>
            state.configurationPriceList.configuration.steps[
                props.step_order - 1
            ].tracking_key
    )
    const priceList = useSelector(
        (state: RootState) => state.configurationPriceList.priceList
    )
    const featureAppVariant = priceList + ' ' + trackingData.APP_VARIANT

    const clickNextStep = (selected: string) => {
        setSelectedCard(selected)
        nextStep(selected, props.data)
        trackingEvent(TrackingAction.VW_BASIC_FILTER_SELECT_CLICK, {
            eventInfo: {
                ...eventInfoCommon,
                contentId: props.data.title,
            },
            environment: {
                ...eventEnvironmentCommon(featureAppVariant),
                commerce: trackingData.COMMERCE,
            },
            link: {
                name: props.data.placeholder,
            },
            filter: {
                type: trackingData.FILTER_TYPE,
                name: filterName,
                values: [selected],
            },
        })
    }

    useEffect(() => {
        if (props.currentStep === props.step_order && !editingMode) {
            setLoadingData(true)

            dispatch(fetchSteps())
                .then(() => {
                    setLoadingData(false)

                    const queryParam: string | null = URL_PARAMS.get(
                            `step${props.currentStep}`
                        ),
                        listOptions =
                            store.getState().steps.steps[props.currentStep - 1]

                    if (
                        window.location.search &&
                        queryParam &&
                        listOptions.includes(queryParam)
                    ) {
                        clickNextStep(queryParam)
                    }

                    if (listOptions.length === Global.AUTOMATIC_CHOICE) {
                        clickNextStep(listOptions[0])
                        setEditableData(false)
                    } else {
                        setEditableData(true)
                    }
                })
                .catch(() => {
                    setLoadingData(false)
                })
        }
    }, [props.currentStep])

    return (
        <StepContainerBorder
            className={`${
                props.currentStep > props.step_order ? 'preview' : ''
            } ${props.currentStep < props.step_order ? 'hidden' : ''}`}
        >
            {props.currentStep > props.step_order ? (
                <>
                    <EditComponent
                        label={props.data.placeholder}
                        stepID={props.step_order}
                        value={selectedCard}
                        showCta={editableData}
                    ></EditComponent>
                </>
            ) : (
                <>
                    <Container
                        padding={{
                            bottom: {
                                [Breakpoints.default]: ContainerPadding.grid002,
                                [Breakpoints.b560]: ContainerPadding.static450,
                            },
                        }}
                    >
                        <Text
                            bold
                            tag={TextTag.h2}
                            appearance={TokenTextAppearance.headline300}
                        >
                            {props.data.title}
                        </Text>
                    </Container>
                    {!loadingData ? (
                        <>
                            {!isEmpty(cardsValues) &&
                            props.step_order === props.currentStep ? (
                                <CardsContainer>
                                    {cardsValues.map((cardValue: number) => {
                                        return (
                                            <BorderCard
                                                role="button"
                                                key={cardValue}
                                                onClick={() =>
                                                    clickNextStep(
                                                        String(cardValue)
                                                    )
                                                }
                                            >
                                                {props.data.header ? (
                                                    <Text
                                                        textAlign={{
                                                            [Breakpoints.default]:
                                                                TextAlignment.left,
                                                            [Breakpoints.b560]:
                                                                TextAlignment.center,
                                                        }}
                                                        tag={TextTag.p}
                                                        appearance={
                                                            TokenTextAppearance.copy200
                                                        }
                                                    >
                                                        {props.data.header}
                                                    </Text>
                                                ) : undefined}
                                                <KmContainer>
                                                    <Text
                                                        bold
                                                        tag={TextTag.span}
                                                        appearance={
                                                            TokenTextAppearance.headline300
                                                        }
                                                    >
                                                        {cardValue}
                                                    </Text>
                                                    <Text
                                                        tag={TextTag.p}
                                                        appearance={
                                                            TokenTextAppearance.copy200
                                                        }
                                                    >
                                                        {props.data.footer}
                                                    </Text>
                                                </KmContainer>
                                            </BorderCard>
                                        )
                                    })}
                                </CardsContainer>
                            ) : undefined}
                        </>
                    ) : (
                        <Loading />
                    )}

                    <CardsContainer></CardsContainer>
                </>
            )}
        </StepContainerBorder>
    )
}
