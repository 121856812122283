import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/rootReducer'
import {fetchConfigurationPriceList} from '../../app/thunk'

export const configurationPriceListSlice = createSlice({
    name: 'configurationPriceList',
    initialState: {
        priceList: '',
        configuration: {},
    },
    reducers: {
        setPriceListName(
            state: RootState,
            action: PayloadAction<string>
        ): void {
            state.priceList = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchConfigurationPriceList.fulfilled,
            (state: RootState, action) => {
                state.configuration = action.payload
                state.configuration.steps.map(
                    (step: any, index: number) =>
                        (step['step_order'] = index + 1)
                )
            }
        )
    },
})

export const {setPriceListName} = configurationPriceListSlice.actions

export default configurationPriceListSlice.reducer
