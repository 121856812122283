import {createAsyncThunk} from '@reduxjs/toolkit'
import store from './store'
import axios from 'axios'
import {openAlertSystemLayer} from './layers/launchers/AlertSystemLayerHandle'
import {ConfigurationPriceList} from '../types/configurationPriceList'
import {Global} from '../shared/variables'
import {ConfigurationPromo} from '../types/configurationPromo'
import {setLoadingCoupon} from '../features/configurationCoupon/configurationCouponSlice'

export const fetchConfigurationPriceList = createAsyncThunk(
    'configurationPromo/fetchConfigurationPromo',
    async () => {
        try {
            const response = await axios.get<ConfigurationPriceList>(
                `${store.getState().general.backendUrl}/price-lists/${
                    store.getState().configurationPriceList.priceList
                }`
            )
            return response.data
        } catch (err: any) {
            if (err.response.status === Global.ERROR_404) {
                return
            } else {
                openAlertSystemLayer()
                throw err
            }
        }
    }
)

export const fetchConfigurationCoupon = createAsyncThunk(
    'configurationCoupon/fetchConfigurationCoupon',
    async () => {
        try {
            const response = await axios.get<ConfigurationPromo>(
                `${store.getState().general.backendUrl}/promotions/${
                    store.getState().configurationPriceList.configuration
                        .coupon_key
                }`
            )
            return response.data
        } catch (err: any) {
            if (err.response.status === Global.ERROR_404) {
                store.dispatch(setLoadingCoupon(false))
                return
            } else {
                openAlertSystemLayer()
                throw err
            }
        }
    }
)

export const fetchSteps = createAsyncThunk('steps/fetchSteps', async () => {
    try {
        const response = await axios.post<ConfigurationPriceList>(
            `${store.getState().general.backendUrl}/price-lists/${
                store.getState().configurationPriceList.priceList
            }/price-list-values`,
            store.getState().steps.choices
        )
        return response.data
    } catch (err) {
        openAlertSystemLayer()
        throw err
    }
})

export type AppDispatch = typeof store.dispatch
