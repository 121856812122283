import {
    Breakpoints,
    CTA,
    styled,
    Text,
    TextAlignment,
    TokenTextAppearance,
    TextTag,
} from '@volkswagen-onehub/components-core'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
    ArrowRight,
    ChevronDown,
    ChevronUp,
    Manual,
    Info,
} from '@volkswagen-onehub/icons-core'
import {Result} from '../../types/result'
import isEmpty from 'lodash.isempty'
import {ConfigurationPriceList} from '../../types/configurationPriceList'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../rootReducer'
import {Global} from '../../shared/variables'
import {useFeatureServices} from '../../use-feature-services'
import {AccordionWrapper} from './AccordionWrapper'
import {AppDispatch, fetchConfigurationCoupon} from '../thunk'
import {Loading} from './Loading'
import {
    eventEnvironmentCommon,
    eventInfoCommon,
    TrackingAction,
    trackingData,
    trackingEvent,
} from '../../shared/trackingServiceHelper'
import {setLoadingCoupon} from '../../features/configurationCoupon/configurationCouponSlice'
import legalNotesLayerHandle from '../layers/launchers/LegalNotesLayerHandle'
import {
    setIsLegalNotesOpen,
    setLegalNotesText,
} from '../../features/general/generalSlice'

const StepContainer = styled.div`
    padding: 2.5rem 0;
    position: relative;
    margin: 0 ${(props) => props.theme.size.grid002};

    @media (min-width: ${Breakpoints.b960}px) {
        padding: 3rem 0;
        margin: 0 ${(props) => props.theme.size.grid003};
    }
`

const ResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const ResultsCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(props) => props.theme.size.static450};

    @media (min-width: ${Breakpoints.b960}px) {
        margin-bottom: ${(props) => props.theme.size.static500};
    }
`

const ResultsCard = styled.div`
    display: flex;
    flex-direction: column;
    border: 2px solid ${({theme}) => theme.interaction.recipe550.default};
    margin-top: ${(props) => props.theme.size.static300};
    padding: ${(props) => props.theme.size.static400}
        ${(props) => props.theme.size.static200};

    a {
        align-self: start;
    }

    @media (min-width: ${Breakpoints.b560}px) {
        padding: ${(props) => props.theme.size.static450}
            ${(props) => props.theme.size.static500};
    }
`

const ResultsCardTitle = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${Breakpoints.b560}px) {
        flex-direction: row;
        justify-content: space-between;
    }

    div {
        margin: ${(props) => props.theme.size.static300} 0;
        @media (min-width: ${Breakpoints.b560}px) {
            margin: 0;
        }
    }
`

const DetailsText = styled(Text)`
    overflow: hidden;
    margin: ${(props) => props.theme.size.static150} 0;
`
const ContainerPdf = styled(Text)`
    position: relative;
    margin-top: ${(props) => props.theme.size.static500};
`
const ContainerCTA = styled(Text)`
    position: relative;
    margin-top: ${(props) => props.theme.size.static500};
`
const ContainerMultiCTA = styled.div`
    display: inline-flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 48px;
`
const ContainerRecoverCTA = styled(Text)`
    position: relative;
    margin-bottom: ${(props) => props.theme.size.static450};
`
const ContainerLegalInfo = styled.div`
    p {
        display: inline;
        margin-bottom: 0;

        > span div {
            display: inline;
        }
    }
`
const LegalNotesContainer = styled.article`
    color: ${({theme}) => theme.interaction.recipe200.default};
    margin-top: ${(props) => props.theme.size.static450};
`
const CTAContainer = styled.div`
    margin-top: 15px;

    a {
        display: inline-block;

        > * {
            pointer-events: none;
        }

        span {
            display: flex;
            align-items: center;
        }

        svg {
            width: 22px;
            height: 22px;
            display: inline-block;
            margin-right: 10px;
        }
    }
`
const LegalNotesCta = styled(CTA)`
    font-size: 14px;

    @media (min-width: ${Breakpoints.b560}px) {
        font-size: 16px;
    }
`

interface ResultsComponentProps {
    data: ConfigurationPriceList
    results: Result[]
}

export function ResultsComponent(
    props: ResultsComponentProps
): React.JSX.Element {
    const {t} = useTranslation('fa-listini')
    const dispatch = useDispatch() as AppDispatch

    const {'ipc-service': ipcService}: any = useFeatureServices() as any
    const {'layer-manager': layerManager}: any = useFeatureServices() as any

    const legalNotesCtaText = t('cta.singlePromotion.legalNotes')

    const [visibilityDetailsText, setVisibilityDetailsText] = useState(false)

    const configurationPriceList = useSelector(
        (state: RootState) => state.configurationPriceList.configuration
    )
    const configurationCoupon = useSelector(
        (state: RootState) => state.configurationCoupon.configuration
    )
    const loadingCoupon = useSelector(
        (state: RootState) => state.configurationCoupon.loadingCoupon
    )
    const faCouponData = useSelector(
        (state: RootState) => state.steps.faCouponData
    )
    const filterList = useSelector(
        (state: RootState) => state.steps.trackingChoices
    )
    const priceList = useSelector(
        (state: RootState) => state.configurationPriceList.priceList
    )
    const featureAppVariant = priceList + ' ' + trackingData.APP_VARIANT
    const subStringDescription = (text: string) => {
        if (text === null) {
            return ''
        }
        return text.substring(0, Global.LIMIT_RESULT_DESCRIPTION) + '...'
    }

    useEffect(() => {
        if (configurationPriceList.coupon_key) {
            setLoadingCoupon(true)
            dispatch(fetchConfigurationCoupon())
                .then(() => setLoadingCoupon(false))
                .catch(() => setLoadingCoupon(false))
        }
    }, [])

    useEffect(() => {
        trackingEvent(TrackingAction.VW_BASIC_FILTER_SEARCH_RESULT_LOAD, {
            eventInfo: {
                ...eventInfoCommon,
                contentId: props.data.result_title,
            },
            environment: {
                ...eventEnvironmentCommon(featureAppVariant),
            },
            filter: {
                results: props.results,
                numberOfResults: props.results.length,
                filterlist: filterList,
                sortOrder: undefined,
            },
        })
    }, [])

    const openFaCoupon = (promo: string) => {
        ipcService.put('fs-coupon', 'priceListData', {
            ...faCouponData,
            result: promo,
        })
        ipcService.publish('setPriceListData', 'onChangePriceListData')
    }

    const recoverFaCoupon = () => {
        ipcService.put('fs-coupon', 'openRecoverFormLayer', true)
        ipcService.publish('recoverFormLayer', 'onOpenRecoverFormLayer')
    }

    return (
        <StepContainer>
            <ResultsContainer>
                {props.data.result_title ? (
                    <Text
                        bold
                        tag={TextTag.h2}
                        appearance={TokenTextAppearance.headline300}
                    >
                        {props.data.result_title}
                    </Text>
                ) : undefined}

                {props.results[0].pdf_download_link ? (
                    <ContainerPdf>
                        <CTA
                            tag="a"
                            emphasis="secondary"
                            size="small"
                            target="_blank"
                            href={props.results[0].pdf_download_link}
                            ariaLabel={t('cta.openPDF')}
                            stretchContent={false}
                            icon={<Manual variant="small" />}
                            onClick={() =>
                                trackingEvent(
                                    TrackingAction.VW_BASIC_BUTTON_CLICK,
                                    {
                                        eventInfo: {
                                            ...eventInfoCommon,
                                            contentId: props.data.result_title,
                                        },
                                        environment: {
                                            ...eventEnvironmentCommon(
                                                featureAppVariant
                                            ),
                                            commerce: trackingData.COMMERCE,
                                        },
                                        link: {
                                            name: t('cta.openPDF'),
                                            url: props.results[0]
                                                .pdf_download_link,
                                        },
                                    }
                                )
                            }
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('cta.openPDF'),
                                }}
                            />
                        </CTA>
                    </ContainerPdf>
                ) : undefined}

                <ResultsCardContainer>
                    {!isEmpty(props.results) ? (
                        <>
                            {props.results.map(
                                (result: Result, index: number) => {
                                    return (
                                        <ResultsCard key={index}>
                                            <ResultsCardTitle>
                                                {result.title ? (
                                                    <Text
                                                        bold
                                                        tag={TextTag.h3}
                                                        appearance={
                                                            TokenTextAppearance.headline200
                                                        }
                                                    >
                                                        {result.title}
                                                    </Text>
                                                ) : undefined}

                                                <div>
                                                    {result.discounted_price_formatted ? (
                                                        <Text
                                                            bold
                                                            textAlign={{
                                                                [Breakpoints.default]:
                                                                    TextAlignment.left,
                                                                [Breakpoints.b560]:
                                                                    TextAlignment.right,
                                                            }}
                                                            tag={TextTag.p}
                                                            appearance={
                                                                TokenTextAppearance.copy200
                                                            }
                                                        >
                                                            {result.discounted_price_formatted +
                                                                ' €'}
                                                        </Text>
                                                    ) : undefined}

                                                    {result.full_price_formatted ? (
                                                        <Text
                                                            textAlign={{
                                                                [Breakpoints.default]:
                                                                    TextAlignment.left,
                                                                [Breakpoints.b560]:
                                                                    TextAlignment.right,
                                                            }}
                                                            tag={TextTag.p}
                                                            appearance={
                                                                TokenTextAppearance.copy100
                                                            }
                                                        >
                                                            anziché{' '}
                                                            {result.full_price_formatted +
                                                                ' €'}
                                                        </Text>
                                                    ) : undefined}

                                                    {result.additional_price_info ? (
                                                        <Text
                                                            textAlign={{
                                                                [Breakpoints.default]:
                                                                    TextAlignment.left,
                                                                [Breakpoints.b560]:
                                                                    TextAlignment.right,
                                                            }}
                                                            tag={TextTag.p}
                                                            appearance={
                                                                TokenTextAppearance.copy100
                                                            }
                                                        >
                                                            {
                                                                result.additional_price_info
                                                            }
                                                        </Text>
                                                    ) : undefined}
                                                </div>
                                            </ResultsCardTitle>
                                            <Text
                                                bold
                                                tag={TextTag.h4}
                                                appearance={
                                                    TokenTextAppearance.headline200
                                                }
                                            >
                                                {result.subtitle}
                                            </Text>

                                            <DetailsText
                                                appearance={
                                                    TokenTextAppearance.copy200
                                                }
                                            >
                                                {result.description &&
                                                result.description.length <=
                                                    Global.LIMIT_RESULT_DESCRIPTION ? (
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: result.description,
                                                        }}
                                                    ></span>
                                                ) : (
                                                    <>
                                                        {visibilityDetailsText ? (
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: result.description,
                                                                }}
                                                            ></span>
                                                        ) : (
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: subStringDescription(
                                                                        result.description
                                                                    ),
                                                                }}
                                                            ></span>
                                                        )}
                                                    </>
                                                )}
                                            </DetailsText>

                                            {result.description &&
                                            result.description.length >
                                                Global.LIMIT_RESULT_DESCRIPTION ? (
                                                <CTA
                                                    tag="a"
                                                    emphasis="tertiary"
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setVisibilityDetailsText(
                                                            !visibilityDetailsText
                                                        )

                                                        if (
                                                            visibilityDetailsText
                                                        ) {
                                                            trackingEvent(
                                                                TrackingAction.VW_BASIC_ACCORDION_CLOSE_CLICK,
                                                                {
                                                                    eventInfo: {
                                                                        ...eventInfoCommon,
                                                                        contentId:
                                                                            result.title +
                                                                            ' - ' +
                                                                            result.subtitle,
                                                                    },
                                                                    environment:
                                                                        {
                                                                            ...eventEnvironmentCommon(
                                                                                featureAppVariant
                                                                            ),
                                                                        },
                                                                    link: {
                                                                        name: t(
                                                                            'cta.hideText'
                                                                        ),
                                                                        url: window
                                                                            .location
                                                                            .href,
                                                                    },
                                                                    accordion: {
                                                                        groupItemName:
                                                                            result.subtitle,
                                                                    },
                                                                }
                                                            )
                                                        } else {
                                                            trackingEvent(
                                                                TrackingAction.VW_BASIC_ACCORDION_OPEN_CLICK,
                                                                {
                                                                    eventInfo: {
                                                                        ...eventInfoCommon,
                                                                        contentId:
                                                                            result.title +
                                                                            ' - ' +
                                                                            result.subtitle,
                                                                    },
                                                                    environment:
                                                                        {
                                                                            ...eventEnvironmentCommon(
                                                                                featureAppVariant
                                                                            ),
                                                                        },
                                                                    link: {
                                                                        name: t(
                                                                            'cta.displayText'
                                                                        ),
                                                                        url: window
                                                                            .location
                                                                            .href,
                                                                    },
                                                                    accordion: {
                                                                        groupItemName:
                                                                            result.subtitle,
                                                                        groupItemState:
                                                                            trackingData.ACCORDION_OPEN,
                                                                    },
                                                                }
                                                            )
                                                        }
                                                    }}
                                                    ariaLabel={t(
                                                        'cta.displayText'
                                                    )}
                                                    icon={
                                                        visibilityDetailsText ? (
                                                            <ChevronUp />
                                                        ) : (
                                                            <ChevronDown />
                                                        )
                                                    }
                                                >
                                                    {visibilityDetailsText ? (
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: t(
                                                                    'cta.hideText'
                                                                ),
                                                            }}
                                                        />
                                                    ) : (
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: t(
                                                                    'cta.displayText'
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                </CTA>
                                            ) : undefined}

                                            {result.accordion_title &&
                                            result.accordion_content ? (
                                                <AccordionWrapper
                                                    isOpen={false}
                                                    content={
                                                        result.accordion_content
                                                    }
                                                    headline={
                                                        result.accordion_title
                                                    }
                                                    contentId={
                                                        result.title +
                                                        ' - ' +
                                                        result.subtitle
                                                    }
                                                />
                                            ) : undefined}

                                            {configurationPriceList.has_legal_notes_layer &&
                                            result.legal ? (
                                                <CTAContainer>
                                                    <LegalNotesCta
                                                        tag="a"
                                                        emphasis="tertiary"
                                                        onClick={(
                                                            e: React.MouseEvent
                                                        ): void => {
                                                            e.preventDefault()

                                                            legalNotesLayerHandle(
                                                                layerManager,
                                                                () => {
                                                                    dispatch(
                                                                        setIsLegalNotesOpen(
                                                                            false
                                                                        )
                                                                    )
                                                                    dispatch(
                                                                        setLegalNotesText(
                                                                            ''
                                                                        )
                                                                    )
                                                                }
                                                            )
                                                            dispatch(
                                                                setIsLegalNotesOpen(
                                                                    true
                                                                )
                                                            )
                                                            dispatch(
                                                                setLegalNotesText(
                                                                    result.legal
                                                                )
                                                            )
                                                        }}
                                                    >
                                                        <Info />
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: legalNotesCtaText,
                                                            }}
                                                        />
                                                    </LegalNotesCta>
                                                </CTAContainer>
                                            ) : undefined}

                                            {configurationPriceList.coupon_key ? (
                                                <ContainerCTA>
                                                    {loadingCoupon ? (
                                                        <Loading />
                                                    ) : (
                                                        <>
                                                            {!isEmpty(
                                                                configurationCoupon
                                                            ) ? (
                                                                <CTA
                                                                    tag="button"
                                                                    emphasis="primary"
                                                                    onClick={() => {
                                                                        openFaCoupon(
                                                                            result.title
                                                                        )
                                                                        trackingEvent(
                                                                            TrackingAction.VW_BASIC_CTA_CLICK,
                                                                            {
                                                                                eventInfo:
                                                                                    {
                                                                                        ...eventInfoCommon,
                                                                                        contentId:
                                                                                            result.title +
                                                                                            ' - ' +
                                                                                            result.subtitle,
                                                                                    },
                                                                                environment:
                                                                                    {
                                                                                        ...eventEnvironmentCommon(
                                                                                            featureAppVariant
                                                                                        ),
                                                                                    },
                                                                                link: {
                                                                                    name: configurationCoupon
                                                                                        .cta_opening
                                                                                        .national,
                                                                                    url: window
                                                                                        .location
                                                                                        .href,
                                                                                },
                                                                            }
                                                                        )
                                                                    }}
                                                                    title={
                                                                        configurationCoupon
                                                                            .cta_opening
                                                                            .national
                                                                    }
                                                                    ariaLabel={
                                                                        configurationCoupon
                                                                            .cta_opening
                                                                            .national
                                                                    }
                                                                >
                                                                    <span
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: configurationCoupon
                                                                                .cta_opening
                                                                                .national,
                                                                        }}
                                                                    />
                                                                </CTA>
                                                            ) : undefined}
                                                        </>
                                                    )}
                                                </ContainerCTA>
                                            ) : undefined}
                                        </ResultsCard>
                                    )
                                }
                            )}
                        </>
                    ) : undefined}

                    {Object.keys(configurationPriceList).some((key) =>
                        key.startsWith('cta_')
                    ) && (
                        <ContainerMultiCTA>
                            {[1, 2, 3].map((index) => {
                                const ctaUrl =
                                    configurationPriceList[`cta_${index}_url`]
                                const ctaLabel =
                                    configurationPriceList[`cta_${index}_label`]

                                return ctaUrl && ctaLabel ? (
                                    <CTA
                                        key={`cta_${index}`}
                                        tag="a"
                                        target="_blank"
                                        emphasis={
                                            index === 1
                                                ? 'primary'
                                                : 'secondary'
                                        }
                                        href={ctaUrl}
                                        ariaLabel={t('cta.confirm')}
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: ctaLabel,
                                            }}
                                        />
                                    </CTA>
                                ) : null
                            })}
                        </ContainerMultiCTA>
                    )}
                </ResultsCardContainer>

                {configurationPriceList.coupon_key ? (
                    <ContainerRecoverCTA>
                        {loadingCoupon ? (
                            <Loading />
                        ) : (
                            <>
                                {!isEmpty(configurationCoupon) &&
                                configurationCoupon.coupon.recoverable ? (
                                    <CTA
                                        tag="button"
                                        emphasis="tertiary"
                                        onClick={() => {
                                            recoverFaCoupon()
                                            trackingEvent(
                                                TrackingAction.VW_BASIC_LINK_CLICK,
                                                {
                                                    eventInfo: {
                                                        ...eventInfoCommon,
                                                        contentId:
                                                            props.data
                                                                .result_title,
                                                    },
                                                    link: {
                                                        name: t(
                                                            'cta.recoverCoupon'
                                                        ),
                                                        newTab: false,
                                                        url: window.location
                                                            .href,
                                                    },
                                                }
                                            )
                                        }}
                                        title={t('cta.recoverCoupon')}
                                        ariaLabel={t('cta.recoverCoupon')}
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t('cta.recoverCoupon'),
                                            }}
                                        />
                                    </CTA>
                                ) : undefined}
                            </>
                        )}
                    </ContainerRecoverCTA>
                ) : undefined}

                <ContainerLegalInfo>
                    {props.data.foot_notes ? (
                        <Text
                            tag={TextTag.p}
                            appearance={TokenTextAppearance.copy200}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: props.data.foot_notes,
                                }}
                            />
                            {props.data.foot_link_url &&
                            props.data.foot_link_label ? (
                                <CTA
                                    tag="a"
                                    emphasis="tertiary"
                                    href={props.data.foot_link_url}
                                    ariaLabel={props.data.foot_link_label}
                                    icon={<ArrowRight />}
                                    iconPosition="end"
                                    onClick={() =>
                                        trackingEvent(
                                            TrackingAction.VW_BASIC_LINK_CLICK,
                                            {
                                                eventInfo: {
                                                    ...eventInfoCommon,
                                                    contentId:
                                                        props.data.result_title,
                                                },
                                                link: {
                                                    name: props.data
                                                        .foot_link_label,
                                                    newTab: props.data
                                                        .foot_link_url,
                                                },
                                            }
                                        )
                                    }
                                >
                                    {props.data.foot_link_label}
                                </CTA>
                            ) : undefined}
                        </Text>
                    ) : undefined}
                </ContainerLegalInfo>

                {!configurationPriceList.has_legal_notes_layer &&
                props.results[0].legal ? (
                    <LegalNotesContainer>
                        <Text
                            bold
                            tag={TextTag.p}
                            appearance={TokenTextAppearance.copy100}
                        >
                            {t('legal_notes.title')}
                        </Text>
                        <Text
                            tag={TextTag.p}
                            appearance={TokenTextAppearance.copy100}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: props.results[0].legal,
                                }}
                            />
                        </Text>
                    </LegalNotesContainer>
                ) : undefined}
            </ResultsContainer>
        </StepContainer>
    )
}
