import {Breakpoints, styled} from '@volkswagen-onehub/components-core'

export const StepContainerBorder = styled.div`
    border: 2px solid ${({theme}) => theme.interaction.recipe300.default};
    position: relative;
    padding: 2.5rem 1.5rem;
    margin: ${(props) => props.theme.size.static450}
        ${(props) => props.theme.size.grid002};
    transition: margin-top 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
        margin-bottom 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);

    @media (min-width: ${Breakpoints.b960}px) {
        padding: 3rem 3.5rem 2.5rem;
        margin-left: ${(props) => props.theme.size.grid003};
        margin-right: ${(props) => props.theme.size.grid003};
    }

    @media (min-width: ${Breakpoints.b1280}px) {
        padding: 3rem 2.5rem 2.5rem;
        margin-left: ${(props) => props.theme.size.grid004};
        margin-right: ${(props) => props.theme.size.grid004};
    }

    &.preview {
        margin: 0;
        padding: 0;
        border: 0;
        border-bottom: 1px solid
            ${({theme}) => theme.interaction.recipe550.default};

        &:first-child {
            border-top: 1px solid
                ${({theme}) => theme.interaction.recipe550.default};
        }
    }

    &.hidden {
        opacity: 0;
        height: 0;
        max-height: 0;
        overflow: hidden;
        padding: 0;
        margin: 0;
    }
`
