import {FeatureAppDefinition, FeatureServices, Logger} from '@feature-hub/core'
import {ReactFeatureApp} from '@feature-hub/react'
import React from 'react'
import {App} from './app/app'
import {FeatureServicesContext} from './use-feature-services'
import './i18n'
import store from './app/store'
import {Provider} from 'react-redux'
import {LayerManagerV2} from '@volkswagen-onehub/layer-manager'
import {ThemeProvider} from '@volkswagen-onehub/components-core'

export interface StarterFeatureAppDependencies extends FeatureServices {
    readonly 's2:logger'?: Logger
    readonly 'layer-manager'?: LayerManagerV2
}

export interface FeatureAppListiniConfig {
    featureAppId?: string
    backendUrl: string
    priceList: string
}

export const featureAppDefinition: FeatureAppDefinition<
    ReactFeatureApp,
    StarterFeatureAppDependencies,
    FeatureAppListiniConfig
> = {
    optionalDependencies: {
        featureServices: {
            's2:logger': '^1.0.0',
            'fa-content-service': '^1.0.0',
            'layer-manager': '^2.5.0',
            'tracking': '^1.0.0',
            'ipc-service': '^1.0.0',
        },
    },
    create: ({featureAppId, featureServices, config}) => {
        const faContentService = featureServices['fa-content-service'] as any

        const backendUrl =
            (faContentService && faContentService.content?.backendUrl) ||
            config?.backendUrl

        const priceList =
            (faContentService && faContentService.content?.priceList) ||
            config?.priceList

        if (!backendUrl) {
            throw new Error('No backendUrl is configured!')
        }

        if (!priceList) {
            throw new Error('No priceList is setted!')
        }

        return {
            render() {
                return (
                    <FeatureServicesContext.Provider value={featureServices}>
                        <ThemeProvider theme="main">
                            <Provider store={store}>
                                <App
                                    featureAppId={featureAppId}
                                    backendUrl={backendUrl}
                                    priceListName={priceList}
                                />
                            </Provider>
                        </ThemeProvider>
                    </FeatureServicesContext.Provider>
                )
            },
        }
    },
}

export default featureAppDefinition
