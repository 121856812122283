import {TrackingManagerV1} from '@volkswagen-onehub/tracking-service'
import store from '../app/store'
import {Step} from '../types/step'

let localTrackingManager: TrackingManagerV1

export const setTrackingServiceManager = (
    trackingManager: TrackingManagerV1
) => {
    localTrackingManager = trackingManager
}

export enum TrackingAction {
    VW_BASIC_FILTER_SELECT_CLICK = 'VWBasic_FilterSelect_Click',
    VW_BASIC_BUTTON_CLICK = 'VWBasic_Button_Click',
    VW_BASIC_CTA_CLICK = 'VWBasic_CTA_Click',
    VW_BASIC_LINK_CLICK = 'VWBasic_Link_Click',
    VW_BASIC_ACCORDION_OPEN_CLICK = 'VWBasic_AccordionOpen_Click',
    VW_BASIC_ACCORDION_CLOSE_CLICK = 'VWBasic_AccordionClose_Click',
    VW_BASIC_FILTER_SEARCH_RESULT_LOAD = 'VWBasic_FilterSearchResult_Load',
    VW_BASIC_FORM_LAYER_LOAD = 'VWBasic_FormLayer_Load',
    VW_BASIC_FORM_LAYER_CLOSE_CLICK = 'VWBasic_FormLayerClose_Click',
}

export const trackingData = {
    APP_VARIANT: 'var.1',
    BRAND: 'passenger',
    COMMERCE: false,
    ACCORDION_OPEN: 'open',
    FILTER_TYPE: 'single_value',
    PERSONALIZATION: 'false',
    PAGE_CATEGORY: 'Area Clienti',
}

export const eventInfoCommon = {
    brand: trackingData.BRAND,
    pageCategory: trackingData.PAGE_CATEGORY,
}
export const eventEnvironmentCommon = (featureAppVariant: string) => {
    return {
        featureAppVariant: featureAppVariant,
    }
}
export const trackingEvent = (event: string, configuration?: any) => {
    const steps = store.getState().configurationPriceList.configuration.steps
    const excelKey = steps.find(
        (obj: Step) =>
            // eslint-disable-next-line no-prototype-builtins
            obj.hasOwnProperty('excel_key') && obj['excel_key'] === 'model'
    )

    let valueModel = undefined

    if (excelKey) {
        valueModel =
            store.getState().steps.choices['step_' + excelKey.step_order]
    }

    return localTrackingManager.track(event, {
        personalization: {
            status: trackingData.PERSONALIZATION,
        },
        ...(excelKey &&
            valueModel && {
                configuration: {
                    CarlineName: valueModel,
                },
            }),
        ...configuration,
    })
}
