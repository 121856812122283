import {
    Breakpoints,
    CTA,
    styled,
    Text,
    TextAlignment,
    TextTag,
    TokenTextAppearance,
} from '@volkswagen-onehub/components-core'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Edit} from '@volkswagen-onehub/icons-core'
import {
    resetResults,
    setCurrentStep,
    setEditingMode,
    updateChoice,
} from '../../features/steps/stepsSlice'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch} from '../thunk'
import {RootState} from '../rootReducer'
import {
    eventEnvironmentCommon,
    eventInfoCommon,
    TrackingAction,
    trackingData,
    trackingEvent,
} from '../../shared/trackingServiceHelper'

const EditContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: start;
    padding: ${(props) => props.theme.size.static400}
        ${(props) => props.theme.size.grid002};
    position: relative;
    transition: padding-bottom 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);

    button {
        span {
            &:last-child {
                display: none;

                @media (min-width: ${Breakpoints.b560}px) {
                    display: block;
                }
            }
        }
    }

    @media (min-width: ${Breakpoints.b560}px) {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        height: 6rem;
        padding: ${(props) => props.theme.size.static350}
            ${(props) => props.theme.size.grid002};
    }

    @media (min-width: ${Breakpoints.b960}px) {
        padding: ${(props) => props.theme.size.static350}
            ${(props) => props.theme.size.grid003};
    }
`

const EditContainerText = styled(Text)`
    width: 18rem;
    overflow-y: visible;
`

const ModifyCTAMobile = styled.button`
    background-color: transparent;
    border: 1px solid ${({theme}) => theme.interaction.recipe300.default};
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    right: ${(props) => props.theme.size.grid002};
    top: 40px;

    @media (min-width: ${Breakpoints.b560}px) {
        display: none;
    }
`

const ModifyCTA = styled(CTA)`
    display: none;
    margin-left: auto;

    @media (min-width: ${Breakpoints.b560}px) {
        display: block;
    }
`

interface EditComponentProps {
    label: string
    stepID: number
    value: string
    showCta: boolean
}

export function EditComponent(props: EditComponentProps): React.JSX.Element {
    const {t} = useTranslation('fa-listini')
    const dispatch = useDispatch() as AppDispatch
    const choices = useSelector((state: RootState) => state.steps.choices)
    const priceList = useSelector(
        (state: RootState) => state.configurationPriceList.priceList
    )
    const featureAppVariant = priceList + ' ' + trackingData.APP_VARIANT

    const updateStep = () => {
        dispatch(setCurrentStep(props.stepID))
        dispatch(setEditingMode(true))
        const updatedChoices = {...choices}
        const keys = Object.keys(updatedChoices)

        for (
            let i = keys.indexOf(`step_${props.stepID}`);
            i < keys.length;
            i++
        ) {
            if (keys[Number(i)]) {
                delete updatedChoices[keys[Number(i)]]
            }
        }

        dispatch(updateChoice(updatedChoices))
        dispatch(resetResults())
    }

    return (
        <EditContainer>
            <EditContainerText
                bold
                tag={TextTag.p}
                appearance={TokenTextAppearance.headline200}
            >
                {props.label}
            </EditContainerText>
            <EditContainerText
                tag={TextTag.p}
                textAlign={{
                    [Breakpoints.default]: TextAlignment.left,
                    [Breakpoints.b560]: TextAlignment.center,
                }}
                appearance={TokenTextAppearance.copy200}
            >
                {props.value}
            </EditContainerText>

            {!props.showCta ? (
                <EditContainerText></EditContainerText>
            ) : (
                <EditContainerText>
                    <ModifyCTAMobile onClick={updateStep}>
                        <Edit variant="small" />
                    </ModifyCTAMobile>
                    <ModifyCTA
                        tag="button"
                        emphasis="secondary"
                        onClick={() => {
                            updateStep()

                            trackingEvent(
                                TrackingAction.VW_BASIC_BUTTON_CLICK,
                                {
                                    eventInfo: {
                                        ...eventInfoCommon,
                                        contentId: props.label,
                                    },
                                    environment: {
                                        ...eventEnvironmentCommon(
                                            featureAppVariant
                                        ),
                                        commerce: trackingData.COMMERCE,
                                    },
                                    link: {
                                        name: t('cta.edit'),
                                        url: window.location.href,
                                    },
                                }
                            )
                        }}
                        ariaLabel={t('cta.edit')}
                        icon={<Edit variant="small" />}
                        size={'small'}
                        iconPosition="start"
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('cta.edit'),
                            }}
                        />
                    </ModifyCTA>
                </EditContainerText>
            )}
        </EditContainer>
    )
}
