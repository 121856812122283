// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import {LayerCloseHandleV2} from '@volkswagen-onehub/layer-manager'
import React from 'react'
import {Provider} from 'react-redux'
import store from '../../store'
import {LegalNotesLayer} from '../LegalNotesLayer'

function configurationDataRenderLayer(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // tslint:disable-next-line:no-any
    state: any,
    // tslint:disable-next-line:no-any
    close: LayerCloseHandleV2<any>
): React.JSX.Element {
    return (
        <Provider store={store}>
            <LegalNotesLayer onCloseClick={() => close('layerContent')} />
        </Provider>
    )
}

// tslint:disable-next-line
export default function legalNotesLayerHandle(
    // tslint:disable-next-line:no-any
    layerManager: any,
    onClose: () => void
) {
    layerManager.openDisclaimerLayer(
        configurationDataRenderLayer,
        {},
        {
            onClose: onClose,
        }
    )
}
