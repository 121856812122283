import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/rootReducer'

export const generalSlice = createSlice({
    name: 'general',
    initialState: {
        featureAppId: '',
        backendUrl: '',
        isLegalNotesOpen: false,
        legalNotesText: '',
    },
    reducers: {
        setFeatureAppId(state: RootState, action: PayloadAction<string>): void {
            state.featureAppId = action.payload
        },
        setBackendUrl(
            state: RootState,
            action: PayloadAction<string | undefined>
        ): void {
            state.backendUrl = action.payload
        },
        setIsLegalNotesOpen(
            state: RootState,
            action: PayloadAction<boolean>
        ): void {
            state.isLegalNotesOpen = action.payload
        },
        setLegalNotesText(
            state: RootState,
            action: PayloadAction<string>
        ): void {
            state.legalNotesText = action.payload
        },
    },
})

export const {
    setFeatureAppId,
    setBackendUrl,
    setLegalNotesText,
    setIsLegalNotesOpen,
} = generalSlice.actions

export default generalSlice.reducer
