import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/rootReducer'
import {fetchConfigurationCoupon} from '../../app/thunk'

export const configurationCouponSlice = createSlice({
    name: 'configurationPriceList',
    initialState: {
        configuration: {},
        loadingCoupon: false,
    },
    reducers: {
        setLoadingCoupon(
            state: RootState,
            action: PayloadAction<boolean>
        ): void {
            state.loadingCoupon = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchConfigurationCoupon.fulfilled,
            (state: RootState, action) => {
                state.configuration = action.payload
            }
        )
    },
})

export const {setLoadingCoupon} = configurationCouponSlice.actions

export default configurationCouponSlice.reducer
