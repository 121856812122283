export enum Global {
    ERROR_404 = 404,
    DROPDOWN_TYPE = 'DROPDOWN',
    CARD_TYPE = 'CARD',
    LIMIT_RESULT_DESCRIPTION = 250,
    AUTOMATIC_CHOICE = 1,
}

export const RESULTS_KEY = 'results'
export const RESULT_KEY = 'result'
export const URL_PARAMS: URLSearchParams = new URLSearchParams(
    window.location.search
)
