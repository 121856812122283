import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'
import VW from './translations/VW/translation.it.json'

const options = {
    interpolation: {
        escapeValue: false,
    },
    debug: false,
    resources: {
        VW: {
            'fa-listini': VW,
        },
    },
    fallbackLng: 'VW',
    defaultNS: 'fa-listini',
    react: {
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        transEmptyNodeValue: '',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
        useSuspense: true,
    },
}

i18next.use(initReactI18next).init(options)

export default i18next
