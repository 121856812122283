import {combineReducers} from '@reduxjs/toolkit'

import {generalSlice} from '../features/general/generalSlice'
import {configurationPriceListSlice} from '../features/configurationPriceList/configurationPriceListSlice'
import {stepsSlice} from '../features/steps/stepsSlice'
import {configurationCouponSlice} from '../features/configurationCoupon/configurationCouponSlice'

const rootReducer: any = combineReducers({
    general: generalSlice.reducer,
    configurationPriceList: configurationPriceListSlice.reducer,
    configurationCoupon: configurationCouponSlice.reducer,
    steps: stepsSlice.reducer,
})

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>
