import {Spinner, styled} from '@volkswagen-onehub/components-core'

import React from 'react'

const LoadingContainer = styled.div`
    background: white;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    justify-content: center;
`

export function Loading(): React.JSX.Element {
    return (
        <LoadingContainer>
            <Spinner variant="x-large" />
        </LoadingContainer>
    )
}
