import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/rootReducer'
import {fetchSteps} from '../../app/thunk'
import {RESULTS_KEY} from '../../shared/variables'

export const stepsSlice = createSlice({
    name: 'steps',
    initialState: {
        currentStep: 1,
        steps: [],
        choices: {},
        trackingChoices: [],
        faCouponData: {},
        results: null,
        editingMode: false,
    },
    reducers: {
        setChoices(
            state: RootState,
            action: PayloadAction<{[key: string]: string}>
        ): any {
            const {key, value} = action.payload
            state.choices[String(key)] = value
        },
        updateChoice(
            state: RootState,
            action: PayloadAction<{[key: string]: string}>
        ): any {
            state.choices = action.payload
        },
        setTrackingChoices(
            state: RootState,
            action: PayloadAction<{[key: string]: string | string[]}>
        ): any {
            state.trackingChoices[state.currentStep - 1] = action.payload
        },
        resetResults(state: RootState): any {
            state.results = null
        },
        setFaCouponData(
            state: RootState,
            action: PayloadAction<{[key: string]: string}>
        ): any {
            const {key, value} = action.payload
            state.faCouponData[String(key)] = value
        },
        setResults(state: RootState, action: PayloadAction): any {
            state.results = action.payload
        },
        setCurrentStep(state: RootState, action: PayloadAction<number>): any {
            state.currentStep = action.payload
        },
        incrementCurrentStep(state: RootState): any {
            ++state.currentStep
        },
        decrementCurrentStep(state: RootState): any {
            --state.currentStep
        },
        setEditingMode(state: RootState, action: PayloadAction<boolean>): any {
            state.editingMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSteps.fulfilled, (state: RootState, action) => {
            if (RESULTS_KEY in action.payload) {
                state.results = action.payload.results
            } else {
                state.steps[state.currentStep - 1] = action.payload
            }
        })
    },
})

export const {
    setChoices,
    updateChoice,
    setTrackingChoices,
    resetResults,
    setCurrentStep,
    setFaCouponData,
    setEditingMode,
    incrementCurrentStep,
} = stepsSlice.actions

export default stepsSlice.reducer
