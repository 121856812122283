import {Accordion} from '@volkswagen-onehub/components-core'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
    eventEnvironmentCommon,
    eventInfoCommon,
    TrackingAction,
    trackingData,
    trackingEvent,
} from '../../shared/trackingServiceHelper'
import {useSelector} from 'react-redux'
import {RootState} from '../rootReducer'

interface AccordionWrapperProps {
    headline: string
    content: string
    isOpen: boolean
    contentId: string
}

export function AccordionWrapper(
    props: AccordionWrapperProps
): React.JSX.Element {
    const {t} = useTranslation('fa-listini')
    const [accordion, showAccordion] = useState(props.isOpen)
    const priceList = useSelector(
        (state: RootState) => state.configurationPriceList.priceList
    )
    const featureAppVariant = priceList + ' ' + trackingData.APP_VARIANT

    return (
        <Accordion
            id={props.headline}
            headline={props.headline}
            isOpen={accordion}
            onClick={() => {
                showAccordion(!accordion)

                if (accordion) {
                    trackingEvent(
                        TrackingAction.VW_BASIC_ACCORDION_CLOSE_CLICK,
                        {
                            eventInfo: {
                                ...eventInfoCommon,
                                contentId: props.contentId,
                            },
                            environment: {
                                ...eventEnvironmentCommon(featureAppVariant),
                            },
                            link: {
                                name: props.headline,
                                url: window.location.href,
                            },
                            accordion: {
                                groupItemName: props.headline,
                            },
                        }
                    )
                } else {
                    trackingEvent(
                        TrackingAction.VW_BASIC_ACCORDION_OPEN_CLICK,
                        {
                            eventInfo: {
                                ...eventInfoCommon,
                                contentId: props.contentId,
                            },
                            environment: {
                                ...eventEnvironmentCommon(featureAppVariant),
                            },
                            link: {
                                name: props.headline,
                                url: window.location.href,
                            },
                            accordion: {
                                groupItemName: props.headline,
                                groupItemState: trackingData.ACCORDION_OPEN,
                            },
                        }
                    )
                }
            }}
        >
            <span
                dangerouslySetInnerHTML={{
                    __html: t(props.content),
                }}
            />
        </Accordion>
    )
}
