import {
    incrementCurrentStep,
    setChoices,
    setEditingMode,
    setFaCouponData,
    setTrackingChoices,
} from '../features/steps/stepsSlice'
import {fetchSteps} from '../app/thunk'
import store from '../app/store'
import {Step} from '../types/step'
import {trackingData} from './trackingServiceHelper'

export const nextStep = (selected: string, data: Step) => {
    if (selected) {
        store.dispatch(
            setChoices({
                key: `step_${data.step_order}`,
                value: selected,
            })
        )

        store.dispatch(
            setTrackingChoices({
                name: store.getState().configurationPriceList.configuration
                    .steps[data.step_order - 1].tracking_key,
                values: [selected],
                type: trackingData.FILTER_TYPE,
            })
        )

        store.dispatch(
            setFaCouponData({
                key: data.excel_key,
                value: selected,
            })
        )
        store.dispatch(incrementCurrentStep())
        store.dispatch(setEditingMode(false))
    }

    if (
        data.step_order ===
        store.getState().configurationPriceList.configuration.steps.length
    ) {
        store.dispatch(fetchSteps())
    }
}
