import {Layout} from '@volkswagen-onehub/components-core'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setAlertSystemLayerHandle} from './layers/launchers/AlertSystemLayerHandle'
import {AppDispatch, fetchConfigurationPriceList} from './thunk'
import {setBackendUrl, setFeatureAppId} from '../features/general/generalSlice'
import {setPriceListName} from '../features/configurationPriceList/configurationPriceListSlice'
import {useFeatureServices} from '../use-feature-services'
import {RootState} from './rootReducer'
import isEmpty from 'lodash.isempty'
import {Step} from '../types/step'
import {CardsComponent} from './components/CardsComponent'
import {SelectComponent} from './components/SelectComponent'
import {ResultsComponent} from './components/ResultsComponent'

import {useTranslation} from 'react-i18next'
import {Global} from '../shared/variables'
import {setTrackingServiceManager} from '../shared/trackingServiceHelper'

interface PropsApp {
    featureAppId: string
    backendUrl: string
    priceListName: string
}

export function App(props: PropsApp): React.JSX.Element {
    const {t} = useTranslation('fa-listini')
    const dispatch = useDispatch() as AppDispatch

    const {'layer-manager': layerManager} = useFeatureServices() as any
    const {tracking: trackingManager} = useFeatureServices() as any

    const configurationPriceList = useSelector(
        (state: RootState) => state.configurationPriceList.configuration
    )
    const currentStep = useSelector(
        (state: RootState) => state.steps.currentStep
    )
    const results = useSelector((state: RootState) => state.steps.results)

    if (typeof props.featureAppId !== 'undefined') {
        dispatch(setFeatureAppId(props.featureAppId))
    }

    if (typeof props.backendUrl !== 'undefined') {
        dispatch(setBackendUrl(props.backendUrl))
    }

    if (typeof props.priceListName !== 'undefined') {
        dispatch(setPriceListName(props.priceListName))
    }

    useEffect(() => {
        setAlertSystemLayerHandle(layerManager)
        setTrackingServiceManager(trackingManager)
    }, [])

    useEffect(() => {
        dispatch(fetchConfigurationPriceList())
    }, [props.priceListName])

    return (
        <Layout>
            {!isEmpty(configurationPriceList) ? (
                <>
                    {configurationPriceList.steps.map((step: Step) => {
                        switch (step.step_type.type) {
                            case Global.DROPDOWN_TYPE:
                                return (
                                    <SelectComponent
                                        data={step}
                                        step_order={step.step_order}
                                        currentStep={currentStep}
                                        key={step.id}
                                    ></SelectComponent>
                                )
                            case Global.CARD_TYPE:
                                return (
                                    <CardsComponent
                                        data={step}
                                        step_order={step.step_order}
                                        currentStep={currentStep}
                                        key={step.id}
                                    ></CardsComponent>
                                )
                            default:
                                return (
                                    <div key={step.id}>
                                        {t(
                                            'handleError.componentNotConfigured'
                                        )}
                                    </div>
                                )
                        }
                    })}

                    {!isEmpty(results) ? (
                        <>
                            <ResultsComponent
                                data={configurationPriceList}
                                results={results}
                            ></ResultsComponent>
                        </>
                    ) : undefined}
                </>
            ) : undefined}
        </Layout>
    )
}
