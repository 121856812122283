import {
    Breakpoints,
    Container,
    ContainerPadding,
    CTA,
    Select,
    styled,
    Text,
    TextTag,
    TokenTextAppearance,
} from '@volkswagen-onehub/components-core'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Step} from '../../types/step'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../rootReducer'
import {AppDispatch, fetchSteps} from '../thunk'
import {StepContainerBorder} from '../../shared/classes'
import {Loading} from './Loading'
import {EditComponent} from './EditComponent'
import {nextStep} from '../../shared/utilities'
import {
    eventEnvironmentCommon,
    eventInfoCommon,
    TrackingAction,
    trackingData,
    trackingEvent,
} from '../../shared/trackingServiceHelper'
import store from '../store'
import {Global, URL_PARAMS} from '../../shared/variables'

const SelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${Breakpoints.b960}px) {
        flex-direction: row;
        margin: 0 ${(props) => props.theme.size.grid001};
    }
`

const MultiSelectContainer = styled.div`
    margin-bottom: ${(props) => props.theme.size.static400};
    position: relative;
    min-height: 48px;
    @media (min-width: ${Breakpoints.b960}px) {
        width: ${(props) => props.theme.size.grid024};
        margin-bottom: 0;
        margin-right: ${(props) => props.theme.size.grid001};
    }
`

interface SelectComponentProps {
    data: Step
    step_order: number
    currentStep: number
}

export function SelectComponent(
    props: SelectComponentProps
): React.JSX.Element {
    const {t} = useTranslation('fa-listini')
    const dispatch = useDispatch() as AppDispatch

    const [loadingData, setLoadingData] = useState(false)
    const [editableData, setEditableData] = useState(true)
    const [selectedOption, setSelectedOption] = useState('')

    const editingMode = useSelector(
        (state: RootState) => state.steps.editingMode
    )
    const options = useSelector(
        (state: RootState) => state.steps.steps[props.step_order - 1]
    )

    const filterName = useSelector(
        (state: RootState) =>
            state.configurationPriceList.configuration.steps[
                props.step_order - 1
            ].tracking_key
    )
    const choices = useSelector((state: RootState) => state.steps.choices)
    const priceList = useSelector(
        (state: RootState) => state.configurationPriceList.priceList
    )
    const featureAppVariant = priceList + ' ' + trackingData.APP_VARIANT

    const clickNextStep = (selected: string) => {
        nextStep(selected, props.data)
        setSelectedOption(selected)
        trackingEvent(TrackingAction.VW_BASIC_BUTTON_CLICK, {
            eventInfo: {
                ...eventInfoCommon,
                contentId: props.data.title,
            },
            environment: {
                ...eventEnvironmentCommon(featureAppVariant),
                commerce: trackingData.COMMERCE,
            },
            link: {
                name: t('cta.confirm'),
                url: window.location.href,
            },
        })
    }

    useEffect(() => {
        if (props.currentStep === props.step_order && !editingMode) {
            setLoadingData(true)

            dispatch(fetchSteps())
                .then(() => {
                    setLoadingData(false)

                    const queryParam: string | null = URL_PARAMS.get(
                            `step${props.currentStep}`
                        ),
                        listOptions =
                            store.getState().steps.steps[props.currentStep - 1]

                    if (
                        window.location.search &&
                        queryParam &&
                        listOptions.includes(queryParam)
                    ) {
                        clickNextStep(queryParam)
                    }

                    if (listOptions.length === Global.AUTOMATIC_CHOICE) {
                        clickNextStep(listOptions[0])
                        setEditableData(false)
                    } else {
                        setEditableData(true)
                    }
                })
                .catch(() => {
                    setLoadingData(false)
                })
        }
    }, [props.currentStep])

    const updateSelectedOption = (e: any) => {
        const {value} = e.target as HTMLInputElement
        setSelectedOption(value)
    }

    return (
        <>
            <StepContainerBorder
                className={`${
                    props.currentStep > props.step_order ? 'preview' : ''
                } ${props.currentStep < props.step_order ? 'hidden' : ''}`}
            >
                {props.currentStep > props.step_order ? (
                    <EditComponent
                        label={props.data.placeholder}
                        stepID={props.step_order}
                        value={selectedOption}
                        showCta={editableData}
                    ></EditComponent>
                ) : (
                    <>
                        <Container
                            padding={{
                                bottom: {
                                    [Breakpoints.default]:
                                        ContainerPadding.static400,
                                    [Breakpoints.b960]:
                                        ContainerPadding.static350,
                                },
                            }}
                        >
                            <Text
                                bold
                                appearance={TokenTextAppearance.headline300}
                                tag={TextTag.h2}
                            >
                                {props.data.title}
                            </Text>
                        </Container>

                        <SelectContainer>
                            <MultiSelectContainer>
                                {!loadingData ? (
                                    <>
                                        {options ? (
                                            <Select
                                                name={props.data.placeholder}
                                                label={props.data.placeholder}
                                                defaultValue={
                                                    choices[
                                                        `step_${props.step_order}`
                                                    ]
                                                }
                                                onChange={(val: any) => {
                                                    updateSelectedOption(val)
                                                    const {target} = val
                                                    trackingEvent(
                                                        TrackingAction.VW_BASIC_FILTER_SELECT_CLICK,
                                                        {
                                                            eventInfo: {
                                                                ...eventInfoCommon,
                                                                contentId:
                                                                    props.data
                                                                        .title,
                                                            },
                                                            environment: {
                                                                ...eventEnvironmentCommon(
                                                                    featureAppVariant
                                                                ),
                                                                commerce:
                                                                    trackingData.COMMERCE,
                                                            },
                                                            link: {
                                                                name: props.data
                                                                    .placeholder,
                                                            },
                                                            filter: {
                                                                type: trackingData.FILTER_TYPE,
                                                                name: filterName,
                                                                values: [
                                                                    target.value,
                                                                ],
                                                            },
                                                        }
                                                    )
                                                }}
                                            >
                                                <option value="">
                                                    {props.data.title}
                                                </option>
                                                {options.map(
                                                    (
                                                        option: string,
                                                        i: number
                                                    ) => {
                                                        return (
                                                            <option
                                                                value={option}
                                                                key={i}
                                                            >
                                                                {option}
                                                            </option>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        ) : undefined}
                                    </>
                                ) : (
                                    <Loading />
                                )}
                            </MultiSelectContainer>

                            <CTA
                                tag="button"
                                emphasis="primary"
                                stretchContent
                                onClick={() => clickNextStep(selectedOption)}
                                disabled={!selectedOption}
                                ariaLabel={t('cta.confirm')}
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t('cta.confirm'),
                                    }}
                                />
                            </CTA>
                        </SelectContainer>
                    </>
                )}
            </StepContainerBorder>
        </>
    )
}
