import {
    CTA,
    Container,
    ContainerGutter,
    ContainerPadding,
    Text,
    styled,
} from '@volkswagen-onehub/components-core'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../rootReducer'

const LegalNotesDisclaimerTextContainer = styled.div`
    p {
        margin: 0;
    }
`

export interface LegalNotesLayerProps {
    onCloseClick: () => void
}

export const LegalNotesLayer = (props: LegalNotesLayerProps) => {
    const legalNotes = useSelector(
        (state: RootState) => state.general.legalNotesText
    )
    const {t} = useTranslation('fa-listini')

    return (
        <Container
            gutter={ContainerGutter.static300}
            padding={ContainerPadding.static350}
            wrap="always"
        >
            <Text>
                <LegalNotesDisclaimerTextContainer
                    dangerouslySetInnerHTML={{
                        __html: legalNotes,
                    }}
                />
            </Text>
            <CTA
                onClick={() => {
                    props.onCloseClick()
                }}
                tag="button"
                emphasis="secondary"
            >
                {t('cta.singlePromotion.legalNotesClose')}
            </CTA>
        </Container>
    )
}
